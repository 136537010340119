import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import {
  TextField,
  FormControl,
  Grid,
  Box,
  Typography,
  Button,
  Paper,
  Container,
  InputLabel,
  NativeSelect,
  InputAdornment,
  Divider,
  Link,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import axios from "../utils/axiosConfig";
import Logo from "../assets/images/cwallet.png";
import Telegram from "../assets/images/telegram.png";
import TelegramX from "../assets/images/telegram-xx.png";
import AddUser from "../assets/images/add-user.png";
import LoginWithTg from "../assets/images/loginwithtg.png";
import "../assets/css/login.css";
import { TaskBar } from "../components/TaskBar";
import { decryptWithAES, encryptWithAES } from "../utils/encrypt";
import { loginCheck } from "../utils/loginCheck";

export default function Login() {
  const [telegram, setTelegram] = useState("");
  const [email, setEmail] = useState("");
  const [viewMode, setViewMode] = useState(0);
  const [params] = useSearchParams();
  const mode = params.get("by");
  const isLogin = sessionStorage.getItem("isLogin");
  const username = sessionStorage.getItem("username");

  if (isLogin == "1" && username != "") {
    if (typeof window !== "undefined") {
      window.location.href = window.location.origin;
    }
  }

  const getSocials = async () => {
    try {
      await axios
      .get(
        `/api/getTelegram`
      )
      .then((response) => {
        sessionStorage.setItem("telegram", response.data);
        setTelegram(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

      await axios
      .get(
        `/api/getEmail`
      )
      .then((response) => {
        sessionStorage.setItem("email", response.data);
        setEmail(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    } catch (error) {
      console.error("Error fetching unconfirmed transactions:");
      return false;
    }
  }

  useEffect(() => {
    getSocials();
    if (mode === "phone") {
      setViewMode(1);
    } else if (mode === "telegram") {
      setViewMode(2);
    }
  }, [mode]);
  const handleLoginPhone = async () => {
    setViewMode(1);
  };
  const handleLoginTelegram = async () => {
    setViewMode(2);
  };

  const HeaderLogo = () => {
    return (
      <Box
        display={"flex"}
        alignItems={"center"}
        height={200}
        justifyContent={"center"}
        flexDirection={"column"}
      >
        <img src={Logo} />
        <Typography fontSize={20} fontWeight={900} lineHeight={"23.44px"}>
          Cwallet
        </Typography>
      </Box>
    );
  };
  const LoginPage = () => {
    return (
      <React.Fragment>
        <HeaderLogo />
        <Box>
          <Button variant="contained" className="login-button">
            Login with Email/Username
          </Button>
          <Button
            variant="contained"
            style={{ marginTop: "16px" }}
            color="info"
            className="login-button"
          >
            Login with Phone Number
          </Button>
          <Button
            variant="outlined"
            style={{ marginTop: "16px" }}
            className="login-button outline-view"
            startIcon={<img src={Telegram} />}
          >
            Login with Telegram
          </Button>
          <Divider textAlign="center" className="text-color p-2">
            Or
          </Divider>
          <Button className="login-button guest-button">Login as guest</Button>
        </Box>
        <Box className="fixed bottom-6 fixed-center">
          <Typography className="text-color">New to Wallet?</Typography>
          <Button
            variant="contained"
            className="create-button"
            startIcon={<img src={AddUser} />}
          >
            Create an Account
          </Button>
        </Box>
      </React.Fragment>
    );
  };

  const LoginWithMail = () => {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [open, setOpen] = useState(false);
    const [response, setResponse] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    let telegramLink = 'https://t.me/' + telegram;

    const handleLogin = async () => {
      try {
        await axios
          .post(`/api/login-user`, {
            userName,
            password,
          })
          .then((response) => {
            console.log(response)
            setResponse(response.data.message);
            setOpen(true);
            if (response.data[0].email != "") {
              setIsSuccess(true);
              sessionStorage.setItem("username", response.data[0].username);
              sessionStorage.setItem("email", response.data[0].email);
              sessionStorage.setItem("isLogin", "1");
              if (window) {
                window.location.href = window.location.origin;
              }
            } else {
              setIsSuccess(false);
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } catch (error) {
        console.error("Error fetching unconfirmed transactions:");
        return false;
      }
    };
    return (
      <React.Fragment>
        <HeaderLogo />
        <Box className="p-2">
          <Typography align="left" fontWeight={700}>Username</Typography>
          <TextField
            placeholder="Enter your username"
            className="input-view"
            onChange={(e) => setUserName(e.target.value)}
          />
          <Box className="flex justify-between mt-8">
            <Typography fontWeight={700}>Password</Typography>
            {/* <Link>Forget Password?</Link> */}
          </Box>
          <TextField
            placeholder="Enter your password"
            className="input-view"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            className="login-button log-in"
            variant="contained"
            onClick={handleLogin}
          >
            Log In
          </Button>
          <Box className="flex mt-8 items-center justify-center">
            <Typography>Need Help? Contact</Typography>
            <Link> {email}</Link>
          </Box>
          <Box className="flex mt-8 items-center justify-center">
            <Typography>Telegram: </Typography>
            <a href={telegramLink}>
              <Link><img src={Telegram} className="ml-2 mr-2"/> </Link>
            </a>
          </Box>
          {/* <Divider textAlign="center" className="text-color p-2">
            Or
          </Divider>
          <Button
            variant="contained"
            color="info"
            className="login-button"
            onClick={handleLoginPhone}
          >
            Login with Phone Number
          </Button>
          <Typography className="text-color pt-4">
            Login with social Media.
          </Typography>
          <IconButton onClick={handleLoginTelegram}>
            <img src={TelegramX} />
          </IconButton> */}
        </Box>
        {/* <Box className="fixed bottom-6 fixed-center">
          <Typography className="text-color">New to Wallet?</Typography>
          <Button
            variant="contained"
            className="create-button"
            href="/signup"
            startIcon={<img src={AddUser} />}
          >
            Create an Account
          </Button>
        </Box> */}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          onClose={() => setOpen(false)}
          autoHideDuration={6000}
          key={"bottomcenter"}
        >
          <Alert severity={`${isSuccess ? "success" : "warning"}`}>
            {response}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  };
  const LoginWithPhone = () => {
    const [phoneNumber, setPhoneNumber] = useState("+33");
    const handlePhoneChange = (phoneNumber: string) => {
      setPhoneNumber(phoneNumber);
    };
    return (
      <React.Fragment>
        <TaskBar
          name="Login"
          handleBack={() => {
            setViewMode(0);
          }}
        />
        <Box mt={3} className="p-2">
          <Typography align="left">Enter Phone Number</Typography>
          <MuiTelInput
            value={phoneNumber}
            onChange={handlePhoneChange}
            className="input-view"
          />
          <Divider textAlign="center" className="text-color p-2">
            Or
          </Divider>
          <Button
            variant="outlined"
            className="login-button outline-view"
            startIcon={<img src={Telegram} />}
          >
            Login with Telegram
          </Button>
          <Button
            variant="contained"
            color="info"
            className="login-button"
            style={{ marginTop: "16px" }}
          >
            Login with Email/Username
          </Button>
          <Typography className="text-color pt-2">
            By proceeding, you agree to Cwallet's
          </Typography>
          <Link>Terms of Use</Link> & <Link>Privacy Policy</Link>
        </Box>
        <Box className="fixed bottom-6 fixed-center w-full p-4">
          <Typography className="text-color">New to Wallet?</Typography>
          <Button variant="contained" className="login-button">
            Next
          </Button>
        </Box>
      </React.Fragment>
    );
  };
  const LoginWithTelegram = () => {
    const [phoneNumber, setPhoneNumber] = useState("+33");
    const handlePhoneChange = (phoneNumber: string) => {
      setPhoneNumber(phoneNumber);
    };
    return (
      <React.Fragment>
        <TaskBar
          name="Login"
          handleBack={() => {
            setViewMode(0);
          }}
        />
        <Box mt={3} className="p-2">
          <Box className="flex items-center justify-center" pt={4}>
            <Box width={"280px"}>
              <img src={LoginWithTg} className="m-auto" />
              <Typography pt={2}>
                Log in to use your Telegram account with xoauth.top and Cwallet
              </Typography>
            </Box>
          </Box>
          <Typography mt={4}>
            Please enter your phone number in the international format and we
            will send a confirmation message to your account via Telegram.
          </Typography>
          <MuiTelInput
            value={phoneNumber}
            onChange={handlePhoneChange}
            style={{ marginTop: "32px" }}
            // className="input-view"
          />
          <Box className="flex mt-6 items-center justify-center">
            <Button
              variant="text"
              className="small-round-button"
              style={{ color: "#2c99da" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="small-round-button next"
              style={{ background: "#2c99da" }}
            >
              Next
            </Button>
          </Box>
        </Box>
      </React.Fragment>
    );
  };

  const getView = () => {
    switch (viewMode) {
      case 0:
        return <LoginWithMail />;
      case 1:
        return <LoginWithPhone />;
      case 2:
        return <LoginWithTelegram />;
    }
  };
  return (
    <React.Fragment>
      <Container
        component="main"
        maxWidth="sm"
        className="relative"
        sx={{ mb: 4 }}
        style={{ borderRadius: "10px", height: "100%" }}
      >
        {getView()}
      </Container>
    </React.Fragment>
  );
}
