import React from "react";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import "./App.css";
import Checkout from "./pages/Checkout";
import "tailwindcss/tailwind.css";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Verify from "./pages/Verify";
import ForgotPassword from "./pages/ForgotPassword";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import History from "./pages/History";

const appTheme = createTheme({
  palette: {
    primary: {
      main: "#3961FB",
    },
    info: {
      main: "#000",
    },
    text: {
      primary: "#58667E",
    },
  },
  // components: {
  //   MuiOutlinedInput: {
  //     style: {padding: "11px 14px"}
  //   }
  // }
});

function App() {
  return (
    <ThemeProvider theme={appTheme}>
      <StyledEngineProvider>
        <div className="App">
          <Routes>
            <Route path="/" element={<Checkout />} />
            <Route path="/login" element={<Login />} />
            {/* <Route path="/signup" element={<Signup />} /> */}
            {/* <Route path="/verify" element={<Verify />} /> */}
            {/* <Route path="/forget-password" element={<ForgotPassword />} /> */}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/history" element={<History />} />
          </Routes>
        </div>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
