import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import html2canvas from "html2canvas";
import * as htmlToImage from "html-to-image";
import BtcIcon from "../assets/images/bitcoin.png";
import EthIcon from "../assets/images/eth.png";
import DogecoinIcon from "../assets/images/dogecoin.png";
import LitecoinIcon from "../assets/images/litecoin.png";
import PaymentIcon from "../assets/images/payment.png";
import UsdtIcon from "../assets/images/usdt.png";
import { useNavigate } from "react-router-dom";
import React, { useRef } from "react";
import { TaskBar } from "./TaskBar";
import { getSymbol } from "../utils";
import { Download } from "@mui/icons-material";

export const TransactionDetail = (props: {
  amount: string;
  transactionId: string;
  network: string;
  usdAmount: string;
  agentCode: string;
  date: string;
  setOpen: (val: boolean) => void;
  status: string;
}) => {
  const ref = useRef<HTMLElement | null>(null);
  const [telegram, setTelegam] = useState("");
  const [email, setEmail] = useState("");
  const [telegramLink, setTelegamLink] = useState("");
  useEffect(() => {
    const res = sessionStorage.getItem("telegram");
    const res_email = sessionStorage.getItem("email");
    setTelegam(res);
    setEmail(res_email);
    setTelegamLink("https://t.me/" + res);
  })

  const takeScreenShot = async (node) => {
    const dataURI = await htmlToImage.toJpeg(node);
    return dataURI;
  };

  const createFileName = (extension = "", ...names) => {
    if (!extension) {
      return "";
    }
  
    return `${names.join("")}.${extension}`;
  };

  const download = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

  return (
    <React.Fragment>
      <Box ref={ref} pb={4} style={{background: "white", height: "100%"}}>
        <TaskBar name="Payment" handleBack={() => props.setOpen(false)} />
        <Box className="mt-8 mb-8 flex items-center justify-center flex-col">
          <img src={PaymentIcon} />
          <Typography fontSize={26} fontWeight={700} mt={2}>
            Thank you
          </Typography>
          <Typography className="text-color">
            Your Payment is Completed
          </Typography>
        </Box>
        <Box className="row p-4">
          <Typography className="text-color" fontWeight={600}>
            Status
          </Typography>
          <Typography
            className="text-color"
            fontWeight={600}
            style={{
              padding: "4px 8px",
              borderRadius: "4px",
              background: "#34A85339",
              color: "rgb(52, 168, 83)",
            }}
          >
            {props.status}
          </Typography>
        </Box>
        <Box className="row p-4">
          <Typography className="text-color" fontWeight={600}>
            Agent Code
          </Typography>
          <Typography className="text-color" fontWeight={600}>
            {props.agentCode}
          </Typography>
        </Box>
        <Box className="row p-4">
          <Typography className="text-color" fontWeight={600}>
            Order number
          </Typography>
          <Typography className="text-color" fontWeight={600}>
            {props.transactionId}
          </Typography>
        </Box>
        <Box className="row p-4">
          <Typography className="text-color" fontWeight={600}>
            Date & Time
          </Typography>
          <Typography className="text-color" fontWeight={600}>
            {props.date}
          </Typography>
        </Box>
        <Divider />
        <Box className="row p-4">
          <Typography className="text-color" fontWeight={600}>
            Total
          </Typography>
          <Box className="text-right">
            <Typography fontWeight={600}>${props.usdAmount}</Typography>
            <Typography className="text-color" fontWeight={600}>
              {props.amount}
              {getSymbol(props.network)}
            </Typography>
          </Box>
        </Box>
        <Box className="flex mt-8 items-center justify-center">
          <Typography>Need Help? Contact</Typography>
          <Link>{email}</Link>
        </Box>
        <Box className="flex mt-8 items-center justify-center">
          <Typography>Telegram: </Typography>
          <a href={telegramLink}>
            <Link>@{telegram}</Link>
          </a>
        </Box>
        {/* <Box className="fixed bottom-2 fixed-center w-full p-2">
          <Button
            variant="contained"
            className="login-button"
            startIcon={<Download />}
            onClick={downloadScreenshot}
          >
            Download
          </Button>
        </Box> */}
      </Box>
    </React.Fragment>
  );
};
