import { Box, Button, IconButton, Typography, Link } from "@mui/material";
import ArrowBack from "../assets/images/arrow-back.png";
import { useNavigate } from "react-router-dom";

export const TaskBar = (props: {
  name: string;
  handleBack?: () => void;
  viewHistory?: boolean;
}) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <Box className="flex">
      <IconButton
        style={{ width: "40px" }}
        onClick={props.handleBack ?? goBack}
      >
        <img src={ArrowBack} />
      </IconButton>
      <Box className="flex text-center justify-around w-full items-center">
        <Box width={"100%"}>
          <Typography className="text-color">{props.name}</Typography>
        </Box>
        {props.viewHistory && (
          <Box width={130}>
            <Link href="/dashboard">View History</Link>
          </Box>
        )}
      </Box>
    </Box>
  );
};
