import { Box, Button, IconButton, Typography } from "@mui/material";
import BtcIcon from "../assets/images/bitcoin.png";
import EthIcon from "../assets/images/eth.png";
import DogecoinIcon from "../assets/images/dogecoin.png";
import LitecoinIcon from "../assets/images/litecoin.png";
import UsdtIcon from "../assets/images/usdt.png";
import { useNavigate } from "react-router-dom";

export const TransactionRow = (props: {
  id: string;
  amount: string;
  transactionId: string;
  network: string;
  usdAmount: string;
  agentCode: string;
  date: string;
  status: string;
  setOpen: (val: boolean) => void;
  setDetails: (val: {
    usdamount: string;
    amount: string;
    agentcode: string;
    createdAt: string;
    network: string;
    id: string;
    txid: string;
    status: string;
    updatedAt: string;
    remark: string;
  }) => void;
}) => {
  let tokenLogo = UsdtIcon;
  let symbol = "USDT";
  switch (props.network) {
    case "bitcoin":
      tokenLogo = BtcIcon;
      symbol = "BTC";
      break;
    case "btc":
      tokenLogo = BtcIcon;
      symbol = "BTC";
      break;
    case "ethereum":
      tokenLogo = EthIcon;
      symbol = "ETH";
      break;
    case "eth":
      tokenLogo = EthIcon;
      symbol = "ETH";
      break;
    case "dogecoin":
      tokenLogo = DogecoinIcon;
      symbol = "DOGE";
      break;
    case "litecoin":
      tokenLogo = LitecoinIcon;
      symbol = "LTC";
      break;
  }
  return (
    <Box
      className="flex w-full p-4"
      style={{ borderBottom: "1px solid #E4E4E4", cursor: "pointer" }}
      key={props.id}
      onClick={() => {
        props.setOpen(true);
        props.setDetails({
          usdamount: props.usdAmount,
          amount: props.amount,
          agentcode: props.agentCode,
          createdAt: "",
          network: props.network,
          id: props.id,
          txid: props.transactionId,
          status: props.status,
          updatedAt: props.date,
          remark: "",
        });
      }}
    >
      <Box className="text-center flex items-center">
        <img src={tokenLogo} height={"40px"} width={"40px"} />
      </Box>
      <Box className="w-full text-left pl-3">
        <Typography>Agent:{props.agentCode}</Typography>
        <Typography className="text-color">{symbol} {props.date.slice(0,10)} </Typography>
      </Box>
      <Box className="text-center m-auto">
        <Typography> {`$${props.usdAmount}`}</Typography>
      </Box>
    </Box>
  );
};
