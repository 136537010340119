import React, { useEffect, useState, useRef } from "react";
import {
  TextField,
  FormControl,
  Grid,
  Box,
  Typography,
  Button,
  Paper,
  Container,
  InputLabel,
  NativeSelect,
  InputAdornment,
  Divider,
  Link,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { MuiOtpInput } from "mui-one-time-password-input";
import axios from "../utils/axiosConfig";
import Logo from "../assets/images/cwallet.png";
import HeroImage from "../assets/images/hero.png";
import User from "../assets/images/user.png";
import PaymentIcon from "../assets/images/payment.png";
import Wallet from "../assets/images/wallets.png";
import "../assets/css/login.css";
import { TaskBar } from "../components/TaskBar";
import { TransactionRow } from "../components/TransactionRow";
import { loginCheck, redirectToLogin } from "../utils/loginCheck";
import { getSymbol } from "../utils";
import { TransactionDetail } from "../components/TransactionDetail";

export default function Dashboard() {
  const [authenticated, setAuthenticated] = useState(
    sessionStorage.getItem("username")
  );
  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("username");
    if (loggedInUser) {
      setAuthenticated(loggedInUser);
    }
  }, []);
  const username = sessionStorage.getItem("username");
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState({
    usdamount: "",
    amount: "",
    agentcode: "",
    createdAt: "",
    network: "",
    id: "",
    txid: "",
    status: "",
    updatedAt: "",
    remark: "",
  });
  loginCheck();

  const [transactions, setTransactions] = useState([
    {
      usdamount: "",
      amount: "",
      agentcode: "",
      createdAt: "",
      network: "",
      id: "",
      txid: "",
      updatedAt: "",
      remark: "",
      status: "",
    },
  ]);
  useEffect(() => {
    const getTransactions = async () => {
      try {
        await axios
          .get(`/api/transactions?userName=${username}`)
          .then((response) => {
            setTransactions(response.data);
            console.log("debug txs", response.data);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } catch (error) {
        console.error("Error fetching transactions:");
        return false;
      }
    };
    if (username) {
      getTransactions();
    }
  }, [username]);

  const TaskView = () => {
    return (
      <Box className="flex space-between">
        <Box className="flex items-center">
          <img src={Wallet} />
          <Typography className="pl-2" fontSize={18}>
            Hello! <b>{username}</b>
          </Typography>
        </Box>
        <IconButton href="/profile">
          <img src={User} />
        </IconButton>
      </Box>
    );
  };
  const Hero = () => {
    return (
      <Box className="flex p-4 hero-layout">
        <Box className="w-1/2 text-left">
          <Typography fontSize={20}>
            Welcome to <b>Cwallet</b>
          </Typography>
          <Typography className="text-color" fontSize={14}>
            make payment easier and fast
          </Typography>
          <Button
            variant="contained"
            className="pt-4 log-in hero-make-payment"
            href="/"
          >
            Make a payment
          </Button>
        </Box>
        <Box className="flex items-center w-1/2 justify-center">
          <img src={HeroImage} />
        </Box>
      </Box>
    );
  };
  if (!authenticated) {
    redirectToLogin();
  } else {
    return (
      <React.Fragment>
        <Container
          component="main"
          maxWidth="sm"
          className="relative"
          sx={{ mb: 4 }}
          style={{ borderRadius: "10px", height: "100%" }}
        >
          {!open ? (
            <>
              <TaskView />
              <Hero />
              <Box className="flex mt-5 mb-1 w-full justify-between">
                <Typography>Recent Transaction</Typography>
                <Link href="/history">View All</Link>
              </Box>
              <Box>
                {transactions.length == 0 ? (
                  <Box style={{ marginTop: "80px" }}>
                    There is no transactions
                  </Box>
                ) : (
                  transactions
                    .slice(Math.max(transactions.length - 8, 0))
                    .map((transaction) => {
                      return (
                        <TransactionRow
                          id={transaction.id}
                          transactionId={transaction.txid}
                          amount={transaction.amount}
                          network={transaction.network}
                          usdAmount={transaction.usdamount}
                          date={transaction.createdAt}
                          agentCode={transaction.agentcode}
                          status={transaction.status}
                          setOpen={setOpen}
                          setDetails={setDetails}
                        />
                      );
                    })
                )}
              </Box>
              <Button variant="contained" className="make-payment" href="/">
                Make a Payment
              </Button>
            </>
          ) : (
            <TransactionDetail
              network={details.network}
              status={details.status}
              transactionId={details.txid}
              date={details.updatedAt}
              amount={details.amount}
              usdAmount={details.usdamount}
              agentCode={details.agentcode}
              setOpen={setOpen}
            />
          )}
        </Container>
      </React.Fragment>
    );
  }
}
