export const loginCheck = () => {
  const isLogin = sessionStorage.getItem("isLogin");
  const username = sessionStorage.getItem("username");
  if (isLogin !== "1" || username == "") {
    if (typeof window !== "undefined") {
      window.location.href = window.location.origin + "/login";
    }
  }
};

export const redirectToLogin = () => {
  if (typeof window !== "undefined") {
    window.location.href = window.location.origin + "/login";
  }
};
