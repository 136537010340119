import React, { useEffect, useState, useRef } from "react";
import {
  TextField,
  FormControl,
  Grid,
  Box,
  Typography,
  Button,
  Paper,
  Container,
  InputLabel,
  NativeSelect,
  InputAdornment,
  Divider,
  Link,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { MuiOtpInput } from "mui-one-time-password-input";
import axios from "../utils/axiosConfig";
import PaymentIcon from "../assets/images/payment.png";
import HeroImage from "../assets/images/hero.png";
import User from "../assets/images/user.png";
import Wallet from "../assets/images/wallets.png";
import "../assets/css/login.css";
import { TaskBar } from "../components/TaskBar";
import { TransactionRow } from "../components/TransactionRow";
import { loginCheck } from "../utils/loginCheck";
import { getSymbol } from "../utils";
import { TransactionDetail } from "../components/TransactionDetail";

export default function History() {
  const username = sessionStorage.getItem("username");
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState({
    usdamount: "",
    agentcode: "",
    createdAt: "",
    network: "",
    id: "",
    txid: "",
    status: "",
    amount: "",
    updatedAt: "",
    remark: "",
  });

  loginCheck();
  console.log("debug open", open);

  const [transactions, setTransactions] = useState([
    {
      usdamount: "",
      amount: "",
      agentcode: "",
      createdAt: "",
      network: "",
      id: "",
      txid: "",
      updatedAt: "",
      status: "",
      remark: "",
    },
  ]);
  useEffect(() => {
    const getTransactions = async () => {
      try {
        await axios
          .get(`/api/transactions?userName=${username}`)
          .then((response) => {
            setTransactions(response.data);
            console.log("debug txs", response.data);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } catch (error) {
        console.error("Error fetching transactions:");
        return false;
      }
    };
    if (username) {
      getTransactions();
    }
  }, [username]);

  return (
    <React.Fragment>
      <Container
        component="main"
        maxWidth="sm"
        className="relative"
        sx={{ mb: 4 }}
        style={{ borderRadius: "10px", height: "100%" }}
      >
        {!open ? (
          <>
            <TaskBar name="Transaction History" />
            <Box className="mt-8" style={{ borderTop: "1px solid #e4e4e4" }}>
              {transactions.length == 0 ? (
                <Box style={{ marginTop: "80px" }}>
                  There is no transactions
                </Box>
              ) : (
                transactions
                  .slice()
                  .reverse()
                  .map((transaction) => {
                    return (
                      <TransactionRow
                        id={transaction.id}
                        amount={transaction.amount}
                        transactionId={transaction.txid}
                        status={transaction.status}
                        network={transaction.network}
                        usdAmount={transaction.usdamount}
                        date={transaction.createdAt}
                        agentCode={transaction.agentcode}
                        setOpen={setOpen}
                        setDetails={setDetails}
                      />
                    );
                  })
              )}
            </Box>
          </>
        ) : (
          <TransactionDetail
            network={details.network}
            status={details.status}
            transactionId={details.txid}
            date={details.updatedAt}
            amount={details.amount}
            usdAmount={details.usdamount}
            agentCode={details.agentcode}
            setOpen={setOpen}
          />
        )}
      </Container>
    </React.Fragment>
  );
}
