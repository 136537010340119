export function getSymbol(network: string) {
  let symbol = "BTC";
  switch (network) {
    case "ethereum":
      symbol = "ETH";
      break;
    case "eth":
      symbol = "ETH";
      break;
    case "bitcoin":
      symbol = "BTC";
      break;
    case "btc":
      symbol = "BTC";
      break;
    case "trc20_usdt":
      symbol = "USDT";
      break;
    case "erc20_usdt":
      symbol = "USDT";
      break;
    case "dogecoin":
      symbol = "DOGE";
      break;
    case "litecoin":
      symbol = "LTC";
      break;
  }
  return symbol;
}

export const sleep = (seconds: number) => {
  return new Promise(resolve => setTimeout(() => resolve(null), seconds * 1000));
};

