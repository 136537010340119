import React, { useEffect, useState, useRef } from "react";
import QRCode from "qrcode.react";
import axios from "../../utils/axiosConfig";
import {
  Box,
  Button,
  InputAdornment,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import WalletIcon from "../../assets/images/e-wallet.png";
import { CatchingPokemonSharp } from "@mui/icons-material";
import { sleep } from "../../utils";

const CoinPayment = ({
  network,
  amount,
  setActiveStep,
  setStatus,
}: {
  network: string;
  amount: number;
  setActiveStep: (val: number) => void;
  setStatus: (val: string) => void;
}) => {
  const [paymentInfo, setPaymentInfo] = useState("");
  const [receiverAddress, setReceiverAddress] = useState("");
  const [txid, setTransactionId] = useState(
    sessionStorage.getItem("transactionId")
  );
  const [paymentStatus, setPaymentStatus] = useState("");
  const [agentCode, setAgentCode] = useState("");
  const username = sessionStorage.getItem("username");
  const email = sessionStorage.getItem("email");
  const activeStep = sessionStorage.getItem("coinPayActiveStep");
  const agentCodeData = sessionStorage.getItem("coinPayAgentCode");
  const getStatus = sessionStorage.getItem("paymentStatus");
  const coinPayData = sessionStorage.getItem("coinPayData");
  const parsedData = JSON.parse(coinPayData);

  useEffect(() => {
    let txid = sessionStorage.getItem("transactionId");
    let address = sessionStorage.getItem("coinPayWalletAddr");
    let paymentStatus = sessionStorage.getItem("paymentStatus");
    if (txid && txid != null && address && address != null) {
      setTransactionId(txid);
      setReceiverAddress(address);
      setPaymentStatus(paymentStatus || "");
    }
  }, []);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const reqMade = useRef(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let interval: NodeJS.Timeout | undefined;

  const copyToClipboard = async(event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

    navigator.clipboard.writeText(
      paymentStatus !== "pending" ? receiverAddress : txid
    );
    await sleep(2)
    setAnchorEl(null);

  };

  const getWalletAddress = async (agentCodeData: any) => {
    // if (reqMade.current) return;
    // reqMade.current = true;

    await axios
      .get(
        `/api/getWalletAddress?network=${network}&agentCode=${agentCodeData}&userName=${username}&usdAmount=${parsedData.payInfo.usdAmount}&email=${email}&remark=${parsedData.payInfo.remark}`
      )
      .then((response) => {
        const walletAddr = response.data.walletObj.address;
        setReceiverAddress(response.data.walletObj.address);
        setTransactionId(response.data.walletObj.txid);
        sessionStorage.setItem("coinPayWalletAddr", walletAddr);
        sessionStorage.setItem("transactionId", response.data.walletObj.txid);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchData = async () => {
    const coinPayData = sessionStorage.getItem("coinPayData");
    const parsedData = JSON.parse(coinPayData);
    await axios
      .get(
        `/api/transaction-status?txid=${txid}&network=${parsedData.payInfo.network}&usdAmount=${parsedData.payInfo.usdAmount}&agentCode=${agentCodeData}&userName=${username}&remark=${parsedData.payInfo.remark}&email=${email}`
      )
      .then((response) => {
        if (
          response.data.paymentStatus === "completed" ||
          response.data.paymentStatus === "low amount" ||
          response.data.paymentStatus === "success" ||
          response.data.paymentStatus === "over paid"
        ) {
          clearInterval(interval);
          sessionStorage.setItem("coinPayActiveStep", "3");
          sessionStorage.setItem("coinPayUniqueCode", txid);
          setActiveStep(3);
        } else if (response.data.paymentStatus === "pending") {
          setPaymentStatus("pending");
          setStatus("pending");
          sessionStorage.setItem("coinPayActiveStep", "2");
          setActiveStep(2);
          sessionStorage.setItem("paymentStatus", "pending");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    if (agentCodeData) {
      setAgentCode(agentCodeData);
    } else setActiveStep(0);

    if (
      (network != parsedData.payInfo.network && parsedData.payInfo.network) ||
      !reqMade.current
    ) {
      reqMade.current = true;
      if (activeStep != "2") {
        getWalletAddress(agentCodeData);
      }
    }
  }, [network]);

  useEffect(() => {
    interval = setInterval(() => {
      fetchData();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [agentCode, receiverAddress]);

  useEffect(() => {
    let info;
    let amt = Math.ceil(amount * 10 ** 6) * 1.0 / 10 ** 6;
    let amtWithDecimal = amt * 10 ** 18;
    switch (network) {
      case "bitcoin": {
        info = `bitcoin:${receiverAddress}?amount=${amt}`;
        break;
      }
      case "ethereum": {
        info = `ethereum:${receiverAddress}?value=${amt}`;
        break;
      }
      case "dogecoin": {
        info = `dogecoin:${receiverAddress}?amount=${amt}`;
        break;
      }
      case "litecoin": {
        info = `litecoin:${receiverAddress}?amount=${amt}`;
        break;
      }
      case "erc20_usdt": {
        info = `ethereum:${receiverAddress}?value=${amtWithDecimal}&token=USDT`;
        break;
      }
      default: {
        info = `tron:${receiverAddress}?amount=${amt}&token=USDT`;
        break;
      }
    }
    setPaymentInfo(info);
  }, [receiverAddress]);

  return (
    <div>
      <Typography variant="h5" gutterBottom fontWeight={700} className="pt-8">
        {paymentStatus === "pending" || getStatus === "pending"
          ? "Thank you for your payment"
          : "Waiting for payment..."}
      </Typography>
      {(paymentStatus === "pending" || getStatus === "pending") && (
        <>
          <Typography className="text-color">
            Please allow us few minutes
          </Typography>
          <Typography className="text-color">
            for final conformation.
          </Typography>
        </>
      )}
      {paymentStatus !== "pending" && getStatus !== "pending" && (
        <div>
          <span style={{ display: "block", margin: "0px auto" }}>
            <span
              style={{
                display: "inline-block",
                backgroundColor: "rgb(52, 152, 219)",
                width: "15px",
                height: "15px",
                margin: "2px",
                borderRadius: "100%",
                animation:
                  "0.7s linear 0s infinite normal both running react-spinners-BeatLoader-beat",
              }}
            ></span>
            <span
              style={{
                display: "inline-block",
                backgroundColor: "rgb(52, 152, 219)",
                width: "15px",
                height: "15px",
                margin: "2px",
                borderRadius: "100%",
                animation:
                  "0.7s linear 0.35s infinite normal both running react-spinners-BeatLoader-beat",
              }}
            ></span>
            <span
              style={{
                display: "inline-block",
                backgroundColor: "rgb(52, 152, 219)",
                width: "15px",
                height: "15px",
                margin: "2px",
                borderRadius: "100%",
                animation:
                  "0.7s linear 0s infinite normal both running react-spinners-BeatLoader-beat",
              }}
            ></span>
          </span>
        </div>
      )}
      {paymentStatus !== "pending" && getStatus !== "pending" && (
        <QRCode value={paymentInfo} size={200} style={{ margin: "auto" }} />
      )}
      {(paymentStatus === "pending" || getStatus === "pending") && (
        <Typography className="text-color pt-4" fontSize={20}>
          Confirmation Code
        </Typography>
      )}
      <div className="receiver-box flex items-center space-x-2">
        <Box
          className="flex w-full mb-4"
          style={{
            background: "#efefef",
            borderRadius: "8px",
            height: "45px",
            padding: "8px",
            position: "relative",
            overflow: "hidden",
          }}
        >
          {paymentStatus !== "pending" && getStatus !== "pending" && (
            <img
              src={WalletIcon}
              style={{ width: "24px", height: "24px", marginTop: "2px" }}
            />
          )}
          <Typography ref={inputRef} className="text-color pt-1 pl-2">
            {paymentStatus !== "pending" && getStatus !== "pending"
              ? receiverAddress
              : txid}
          </Typography>
          <Button
            onClick={(e) => copyToClipboard(e)}
            sx={{
              ml: 1,
              position: "absolute",
              borderRadius: "8px!important",
              right: "8px",
              bottom: "6px",
              background: "white!important",
              border: "1px solid #CFD6E4",
            }}
          >
            {paymentStatus === "pending" ? "Copy" : "Copy Address"}
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{".MuiTypography-root": {
              padding: "8px 16px"
            }}}
          >
            <Typography sx={{ p: 2 }}>Copied.</Typography>
          </Popover>
        </Box>
      </div>
    </div>
  );
};

export default CoinPayment;
