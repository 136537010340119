import React, { useEffect, useState, useRef } from "react";
import {
  TextField,
  FormControl,
  Grid,
  Box,
  Typography,
  Button,
  Paper,
  Container,
  InputLabel,
  NativeSelect,
  InputAdornment,
  Divider,
  Link,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { MuiOtpInput } from "mui-one-time-password-input";
import Avatar from 'react-avatar';
import axios from "../utils/axiosConfig";
import Logo from "../assets/images/cwallet.png";
import HeroImage from "../assets/images/hero.png";
import User from "../assets/images/user.png";
import Wallet from "../assets/images/wallets.png";
import "../assets/css/login.css";
import { TaskBar } from "../components/TaskBar";
import { TransactionRow } from "../components/TransactionRow";
import { loginCheck } from "../utils/loginCheck";

export default function Profile() {
  const username = sessionStorage.getItem("username");
  const [otp, setOtp] = useState("");
  const [isLogout, setIsLogout] = useState(false)
  const handleChange = (newValue: string) => {
    setOtp(newValue);
  };
  useEffect(() => {
      loginCheck();
  }, [username, isLogout])

  const handleLogin = () => {
    sessionStorage.removeItem("username")
    sessionStorage.removeItem("email")
    sessionStorage.removeItem("isLogin")
    setIsLogout(true)
  }
  return (
    <React.Fragment>
      <Container
        component="main"
        maxWidth="sm"
        className="relative"
        sx={{ mb: 4 }}
        style={{ borderRadius: "10px", height: "100%" }}
      >
        <TaskBar name="User Profile"/>
        <Avatar name={username??""} style={{marginTop: "120px", borderRadius: "50%"}} />
        <Typography mt={2}>{username}</Typography>
        <Box className="fixed bottom-6 fixed-center w-full p-4">
          <Button variant="contained" className="login-button" onClick={handleLogin}>
            Log out
          </Button>
        </Box>
      </Container>
    </React.Fragment>
  );
}
